export const getParticlesEnabled = () => {
    const particlesEnabled = localStorage.getItem('particlesEnabled');
    if (typeof particlesEnabled === 'string') {
        return particlesEnabled === 'true';
    }
    return true;
};
export const toggleParticlesEnabled = () => {
    const particlesEnabled = !getParticlesEnabled();
    localStorage.setItem('particlesEnabled', JSON.stringify(particlesEnabled));
    return particlesEnabled;
};
