var _a;
import confetti from 'canvas-confetti';
import { getParticlesEnabled } from '../ts/containers/navbar/components/notifications/particlesEnabled';
import hasFocus from './hasFocus';
const container = document.querySelector('.container');
if ((_a = container === null || container === void 0 ? void 0 : container.dataset) === null || _a === void 0 ? void 0 : _a.christmasAtmosphere) {
    const christmasAtmosphere = JSON.parse(container.dataset.christmasAtmosphere);
    if (christmasAtmosphere && getParticlesEnabled()) {
        const randomInRange = (min, max) => {
            return Math.random() * (max - min) + min;
        };
        const snowflake = confetti.shapeFromText({ text: '❄️' });
        let then = 0;
        const CRITICAL = 2500;
        const fps = 20;
        const fpsInterval = 1000 / fps;
        const particleCount = 50;
        const snow = () => {
            confetti({
                particleCount: 1,
                startVelocity: 1,
                angle: 180,
                spread: 270,
                ticks: 200,
                useWorker: true,
                origin: {
                    x: Math.random(),
                    y: randomInRange(-0.1, 0.6),
                },
                shapes: [snowflake],
                gravity: randomInRange(0.4, 0.6),
                scalar: randomInRange(1.25, 1.75),
                flat: true,
                disableForReducedMotion: true,
                drift: randomInRange(-0.4, 0.4),
            });
        };
        (function frame() {
            const now = Date.now();
            const elapsed = now - then;
            if (then === 0) {
                then = now;
                for (let i = 0; i < particleCount; i++) {
                    snow();
                }
            }
            else if (elapsed > CRITICAL) {
                if (!hasFocus() && document.hasFocus()) {
                    confetti.reset();
                    return;
                }
                then = now;
            }
            else if (elapsed > fpsInterval) {
                then = now;
                snow();
            }
            requestAnimationFrame(frame);
        }());
    }
}
